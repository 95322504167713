import { T } from "../../../../src/components/Markdown";
import narigon from "../../../../content/blog/a-surprise-scammer/narigon.png";
import scammer from "../../../../content/blog/a-surprise-scammer/scammer.jpg";
import kekw from "@assets/emotes/kekw.png";
import * as React from 'react';
export default {
  T,
  narigon,
  scammer,
  kekw,
  React
};