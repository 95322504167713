// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-preview-jsx": () => import("./../../../src/templates/preview.jsx" /* webpackChunkName: "component---src-templates-preview-jsx" */),
  "component---src-templates-static-preview-jsx": () => import("./../../../src/templates/static-preview.jsx" /* webpackChunkName: "component---src-templates-static-preview-jsx" */)
}

